import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ip from 'ip'
import Layout from '../components/layout'
import Switch from "react-switch"


import {
	Container,
	Section,
	Row,
	Column,
	ScrollableContainer,
} from '../components/layouts'

import {
	CardForm,
	FormProgress,
	Title,
	CheckBox,
	Paragraph,
	BtnField,
	Box,
	Checkout,
	List,
	Link,
	Button
} from '../components/ui'

import {
	clearErrors,
	submitOrgRequest,
	getCategories
} from '../factory'

import theme from '../theme/theme'
import stripePlans from '../theme/stripePlans'
import { getUrlParams } from '../util'


const {
	oneTimeDonation, whatOffer
} = theme.sections

const recaptchaRef = React.createRef()

// TODO: Specific error for page
class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			position: 0,
			valid: false,
			errorP1: '',
			errorP2: '',
			errorP3: '',
			errorP4: '',
			errorP5: '',
			success: false,
			pending: false,
			consent: {
				trial_agree: false,
				processing_agree: false,
				authority_agree: false,
				admin_ip: ip.address()
			},
			admin: {
				f_name: '',
				l_name: '',
				email: '',
				phone: ''
			},
			organization: {
				address: {},
				title: '',
				phone: '',
				email: '',
				website: '',
				category_id: -1
			},
			products: {
				app: false,
				analytics: false,
				oneClick: false,
				marketing: false,
				annual: false,
				monthly: false,
				grant: false,
				grantPlus: false
			},
			firstPress: false,
			price: 1
		}

		this.submitForm = this.submitForm.bind(this)
		this.nextPosition = this.nextPosition.bind(this)
		this.prevPosition = this.prevPosition.bind(this)
		this.formSubmitted = this.formSubmitted.bind(this)
		this.onCheck = this.onCheck.bind(this)
		this.setCategories = this.setCategories.bind(this)
		this.loadFormData = this.loadFormData.bind(this)
		this.handlePlanChange = this.handlePlanChange.bind(this)
		this.onProductCheck = this.onProductCheck.bind(this)
		this.selectAll = this.selectAll.bind(this)
		this.renderProducts = this.renderProducts.bind(this)
	}

	componentWillMount() {
		if(typeof(window) !== 'undefined') {
			// window.location = 'https://www.flourishchange.com/'
		}
	}

	componentDidMount() {
		this.props.clearErrors()
		this.loadFormData()
		this.props.getCategories()
	}

	formSubmitted() {
		window.location.href = '/thankyou'
	}

	onCheck(e) {
		const { consent } = this.state
		this.setState({ consent: { ...consent,  [e.target.name]: e.target.checked } })
	}

	onProductCheck(e) {
		const { products } = this.state
		const currValue = products[e.target.name]
		this.setState({ products: { ...products,  [e.target.name]: !currValue} })
	}

	handlePlanChange() {
		this.setState(prevState => ({ price: prevState.price === 2 ? 1 : 2 }))
	}

	submitForm(e) {
		e.preventDefault()
		if (this.validation()) {
			const { admin, consent } = this.state

			const organization = Object.assign(this.state.organization, {})

			try {
				/* eslint-disable-next-line */
				analytics.identify(this.state.UUID, {
					firstName: admin.f_name,
					lastName: admin.l_name,
					email: admin.email,
					organization: organization.title,
					phone: admin.phone
				})
				/* eslint-disable-next-line */
				analytics.track('Sign Up Form Submitted')
			} catch (err) {
				// ignore
			}

			organization.address = JSON.stringify(organization.address)

			const data = { organization, admin, consent }
			this.setState({ pending: true })
			this.props.submitOrgRequest(data, ()=>this.formSubmitted())
		}
	}

	nextPosition() {
		this.setState({
			position: this.state.position < 4 ? this.state.position + 1 : this.state.position,
		})


		// try {
		// 	/* eslint-disable-next-line */
		// 	analytics.track(`Sign Up Form Page ${this.state.position + 2} Start`)
		// } catch (e) {
		// 	// ignore
		// }


		// if (this.state.position === 0) {
		// 	const { fname, lname, email, phone } = this.state
		//
		// 	try {
		// 		/* eslint-disable-next-line */
		// 		const UUID = analytics.user().anonymousId()
		// 		this.setState({ UUID })
		//
		// 		/* eslint-disable-next-line */
		// 		analytics.identify(UUID, {
		// 			firstName: fname,
		// 			lastName: lname,
		// 			email: email,
		// 			phone: phone
		// 		})
		// 	} catch (e) {
		// 		// ignore
		// 	}
		// }
	}

	selectAll(key) {
		const { products } = this.state
		Object.keys(products).map(product => {
			products[product] = false
			return false
		})
		products[key] = true

		this.setState({products})
		this.nextPosition()
	}

	prevPosition() {
		const { products } = this.state
		Object.keys(products).map(product => {
			products[product] = false
			return false
		})

		this.setState({
			position: this.state.position > 0 ? this.state.position - 1 : this.state.position,
			products
		})
	}


	loadFormData() {
		const data = getUrlParams()

		const admin = Object.assign(this.state.admin, {
			'f_name': data.firstname,
			'l_name': data.lastname,
			'phone': data.phone,
			'email': data.email
		})
		const organization = Object.assign(this.state.organization, { 'title': data.company })
		this.setState({ organization, admin, price: parseInt(data.annual, 10) || 1, grantProducts: parseInt(data.grant, 10) || 0 })
	}

	setCategories(categories) {
		this.setState({ categories })
	}

	renderProducts() {
		const {grantProducts, price} = this.state
		const {oneClickRelationships, analytics, app, marketing, grant, grantPlus}  = whatOffer.content

		const chosenProducts = Object.keys(this.state.products).filter((key) => this.state.products[key])

		if (grantProducts) {
			return (
				<div style={{marginLeft: 25, marginRight: 25, marginBottom: 50}}>
					<Row style={{ marginLeft: 0, marginRight: 0, marginTop: 0}} >
						<Column style={{borderRight: `5px solid ${theme.colors.crusta}`, justifyContent: 'center', fontSize: 20}} center>
							<Title style={{fontSize: 20}} textAlign={'center'} marginBottom={'0.5em'} marginTop={0}>Just the Basics</Title>
						</Column>
						<Column style={{ justifyContent: 'center'}} center>
							<Title fontSize={'20px'} textAlign={'center'} marginBottom={'0em'} marginTop={0}>The Full package</Title>
						</Column>
					</Row>
					<div style={{height: 3, backgroundColor: theme.colors.crusta}}/>
					<Row style={{ marginLeft: 0, marginRight: 0, paddingTop: 10 }} id="pricing">
						<Column style={{borderRight: `5px solid ${theme.colors.crusta}`}}>
							<List list={grant.list} />
						</Column>
						<Column center>
							<List list={grantPlus.list} />
						</Column>
					</Row>
					<div style={{height: 3, backgroundColor: theme.colors.crusta}}/>
					<Row style={{ marginLeft: 0, marginRight: 0, marginTop: 10}}>
						<Column style={{borderRight: `5px solid ${theme.colors.crusta}`, justifyContent: 'center'}} center>
							<Title fontSize={'30px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>{whatOffer.content.grant.pricing[price]}</Title>
							<Button
								bgColor={'white'}
								hoverBg={theme.colors.crusta}
								hoverTextColor={'white'}
								fontColor={theme.colors.crusta}
								name={"grant"}
								onClick={() => {this.onProductCheck({target: {name: 'grant', checked: true}}); this.nextPosition()}}
								style={{margin: 'auto', width: '50%', border: `1px solid ${theme.colors.crusta}`}}>
								Select
							</Button>
						</Column>
						<Column style={{justifyContent: 'center'}} center>
							<Title fontSize={'30px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>{whatOffer.content.grantPlus.pricing[price]}</Title>
							<Button
								bgColor={'white'}
								hoverBg={theme.colors.crusta}
								hoverTextColor={'white'}
								fontColor={theme.colors.crusta}
								onClick={() => {this.onProductCheck({target: {name: 'grantPlus', checked: true}}); this.nextPosition()}}
								style={{margin: 'auto', width: '50%', border: `1px solid ${theme.colors.crusta}`}}>
								Select
							</Button>
						</Column>
					</Row>

				</div>
			)
		}
		return (
			<div>
				<Row style={{ marginLeft: 0, marginRight: 0, marginTop: 25}}>
					<Column style={{borderRight: `5px solid ${theme.colors.crusta}`, justifyContent: 'center' }} center>
						<Title style={{fontSize: 20}} textAlign={'center'} marginTop={0}>One-Click Relationships</Title>
						<div style={{height: 3, backgroundColor: theme.colors.crusta}}/>
						<Title fontSize={'30px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>{oneClickRelationships.pricing[price]}</Title>
												 	<Paragraph textAlign={'center'}  fontSize={'10px'} fontWeight={400} style={{height: '1.5em'}}>	<a href={'https://storage.googleapis.com/resources.flourishchange.com/Flourish%20Texting%20Pricing.pdf'} target="_blank">volume pricing</a></Paragraph>
												 	<CheckBox
													 type="checkbox"
													 name={'oneClick'} style={{margin: 'auto'}} defaultSelected={this.state.products.oneClick} value={Number(this.state.products.oneClick)} onChange={this.onProductCheck}/>
					</Column>
					<Column style={{borderRight: `5px solid ${theme.colors.crusta}`, justifyContent: 'center'}} center>
						<Title fontSize={'20px'} textAlign={'center'} marginTop={0} style={{lineHeight: '3em'}}>Targeted Marketing</Title>
						<div style={{height: 3, backgroundColor: theme.colors.crusta}}/>
						<Title fontSize={'30px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>{marketing.pricing[price]}</Title>
						<Paragraph textAlign={'center'}  fontSize={'10px'} fontWeight={400}>{whatOffer.content.marketing.pricing2}</Paragraph>
						<CheckBox type="checkbox" name={'marketing'} style={{margin: 'auto'}} defaultSelected={this.state.products.marketing} value={this.state.products.marketing} onChange={this.onProductCheck}/>
					</Column>
					<Column style={{borderRight: `5px solid ${theme.colors.crusta}`, justifyContent: 'center'}} center>
						<Title fontSize={'20px'} textAlign={'center'} marginTop={0}>The Flourish App + Dashboard</Title>
						<div style={{height: 3, backgroundColor: theme.colors.crusta}}/>
						<Title fontSize={'30px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>$1,000/yr</Title>
						<Paragraph textAlign={'center'}  fontSize={'10px'} fontWeight={400}>+ 8% donation > $1,000/mo</Paragraph>
						<CheckBox type="checkbox" name={'app'} style={{margin: 'auto'}} defaultSelected={this.state.products.app} value={this.state.products.app} onChange={this.onProductCheck}/>
					</Column>
					<Column style={{justifyContent: 'center'}}>
						<Title fontSize={'20px'} textAlign={'center'} marginTop={0} style={{width: '90%'}}>Deep Analytics <sup>1</sup> + Predictions</Title>
						<div style={{height: 3, backgroundColor: theme.colors.crusta}}/>
						<Title fontSize={'30px'} textAlign={'center'} marginBottom={'0.5em'} marginTop={0} style={{marginBottom: 0}}>{analytics.pricing[price]}</Title>
						<Paragraph textAlign={'center'}  fontSize={'10px'} fontWeight={400} style={{height: '1.5em'}}>{whatOffer.content.analytics.pricing2}</Paragraph>
						<CheckBox type="checkbox" name={'analytics'} style={{margin: 'auto'}} defaultSelected={this.state.products.analytics} value={this.state.products.analytics} onChange={this.onProductCheck}/>
					</Column>
				</Row>
				<Row>
					{(chosenProducts.length === 10 ) ?
						<Column center style={{marginTop: '2em'}}>
							<BtnField disabled={chosenProducts.length === 0}
								onClick={()=> {typeof(window) !== 'undefined' && window.open('https://dashboard.flourishchange.com/register')}}
								style={{width: '50%', margin: 'auto'}}>
									Sign Up Today
							</BtnField>
						</Column>
						:
						<Column center style={{marginTop: '2em'}}>
							<BtnField disabled={chosenProducts.length === 0} onClick={this.nextPosition} style={{width: '50%', margin: 'auto'}}>Next</BtnField>
						</Column>
					}

				</Row>
				<Row style={{ marginTop: 15 }}>
					<Column>
						<Title fontSize={'40px'} textAlign={'center'} marginTop={'1em'}>
														Ready for everything?
						</Title>
						<Paragraph fontSize={'25px'} textAlign={'center'} marginBottom={'0.5em'}>
													So excited that you want all our products? Then let's chat!
						</Paragraph>
					</Column>
				</Row>
				<Row style={{paddingLeft: 40, }}>
					<Column maxWidth={'50%'} style={{marginTop: '2em'}} center>
						<Box
							onClick={()=>this.selectAll('monthly')} hoverColor={'rgba(255, 204, 51, 0.8)'}
							style={{ boxShadow: `0 15px 35px 0 ${theme.colors.buttonsShadow}` }}
							bgColor={theme.colors.crusta}
							marginRight={'0'}
							data={{title: '$850/month', paragraph: 'cancel anytime'}} />
						<Column center style={{marginTop: '2em'}}>
							<BtnField onClick={()=>this.selectAll('monthly')} style={{width: '50%', margin: 'auto'}}>Select</BtnField>
						</Column>
					</Column>
					<Column maxWidth={'50%'} style={{marginTop: '2em'}} center>
						<Box
							onClick={()=>this.selectAll('annual')}
							hoverColor={'rgba(255, 204, 51, 0.8)'}
							style={{ boxShadow: `0 15px 35px 0 ${theme.colors.buttonsShadow}` }}
							bgColor={theme.colors.crusta}
							marginRight={'auto'}
							marginLeft={'0'}
							data={{title: '$8,500/year', paragraph: '2 months free'}}
							action={'Contact Sales'}
						/>
						<Column center style={{marginTop: '2em'}}>
							<BtnField onClick={()=>this.selectAll('annual')} style={{width: '50%', margin: 'auto'}}>Select</BtnField>
						</Column>
					</Column>
				</Row>
			</div>
		)
	}

	render() {
		const { coupon } = this.props
		const {price, grantProducts} = this.state
		const totalPositions = 2

		const chosenProducts = Object.keys(this.state.products).filter((key) => this.state.products[key])
		const plans = chosenProducts.length > 0 && chosenProducts.map((k) => stripePlans[price][k])
		const subTotal = chosenProducts.length > 0 && chosenProducts.map((k) => Number(whatOffer.prices[price][k])).reduce((a, b)=> a + b, 0)
		var bill = subTotal
		var discount = 0

		if (Object.keys(coupon).length !== 0 ) {
			if (coupon.amount_off) {
				discount = coupon.amount_off / 100
				bill = bill - discount
				discount = `-$${discount}`
			} else if (coupon.percent_off) {
				discount = coupon.percent_off
				bill = bill * (1 - discount / 100)
				discount = `-${discount}%`
			}
		}

		return (
			<Layout>
				<Section bg={oneTimeDonation.bg} paddingTop={'8em'} paddingBottom={'3em'}>
					<form onSubmit={()=>{}}>
						<ScrollableContainer position={this.state.position} totalPositions={totalPositions}>
							<Container maxWidth={'950px'}>
								<Row>
									<Column>
										<CardForm style={{ borderRadius: '0  0 8px 8px', maxHeight: 'none' }}>
											<FormProgress totalPositions={totalPositions - 1} currentPosition={0}/>
											<Row style={{ marginTop: 15 }}>
												<Column style={{flex: 3}}>
													<Title fontSize={'50px'} textAlign={'left'} marginBottom={'0em'} style={{margin: 0, padding: "0px 15px"}}>A plan for everyone.</Title>
													<Paragraph fontSize={'25px'} textAlign={'left'} marginBottom={'0.5em'} style={{padding: "0px 15px"}}>
														{grantProducts ? "Select the Grant Management you need" :
															"Select the products that meet your needs"
														}
													</Paragraph>
												</Column>
												<div style={{ display: 'flex', flex: 2, justifyContent: 'center', alignItems: 'center' }}>
													<Column style={{marginBottom: 0}}>
														<Row style={{margin: 0, height: 50}}>
															<Paragraph style={{ fontWeight: 800, lineHeight: '50px', marginRight: 10 }}>Monthly</Paragraph>
															<Switch
																onChange={this.handlePlanChange} checked={this.state.price === 2}
																onColor={theme.colors.sunglow}
																onHandleColor={theme.colors.crusta}
																handleDiameter={50}
																uncheckedIcon={false}
																checkedIcon={false}
																boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
																activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
																height={30}
																width={100}
																className="react-switch"
																id="material-switch"
															/>
															<Paragraph style={{ fontWeight: 800, lineHeight: '50px', marginLeft: 10 }}>Annual</Paragraph>
														</Row>
														{this.state.price === 2 ? <Paragraph style={{ fontWeight: 400, margin: 'auto', marginLeft: 80 }}>{this.state.grantProducts ? "1 month free" : '2 months free'}</Paragraph>
															: <div style={{height: '1.33em'}}/>
														}
													</Column>
												</div>
											</Row>
											{this.renderProducts()}
										</CardForm>
									</Column>
								</Row>
							</Container>

							<Container maxWidth={'950px'}>
								<Row>
									<Column>
										<CardForm style={{ maxHeight: 'none', marginLeft: 50, marginRight: 50 }}>
											<FormProgress totalPositions={totalPositions - 1} currentPosition={2}/>
											<Row style={{ marginTop: 15, marginLeft: 0, marginRight: 0 }}>
												<Column>
													<Title fontSize={'50px'} textAlign={'center'} marginBottom={'0em'}>One Last Thing...</Title>
													<Paragraph fontSize={'25px'} textAlign={'center'} marginBottom={'0.5em'}>Make sure you have all you need and let's do this!</Paragraph>
												</Column>
											</Row>
											<Column style={{ margin: 'auto', marginLeft: 0, marginRight: 0, paddingRight: 0, paddingLeft: 25}}>
												<Row>
													<Column style={{borderRight: `5px solid ${theme.colors.crusta}`, justifyContent: 'center' }} center>
														<Title style={{fontSize: 35}} textAlign={'center'} marginTop={0}>Products</Title>
														<div style={{height: 3, backgroundColor: theme.colors.crusta, marginBottom: 10}}/>
														{chosenProducts.map((product) => (
															<Paragraph fontSize={'20px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>{whatOffer.products[product]}</Paragraph>
														))}
														<div style={{height: 3, backgroundColor: theme.colors.crusta, marginTop: 20, marginBottom: 20}}/>
														{Object.keys(coupon).length !== 0 && <Title style={{fontSize: 25}}>Subtotal</Title>}
														{Object.keys(coupon).length !== 0 && <Title style={{fontSize: 18}}>Discount</Title>}
														{(this.state.products.annual || price === 2) ?
															<Title style={{fontSize: 25}}>Total/year</Title>
															: <Title style={{fontSize: 25}}>Total/month</Title>
														}
													</Column>
													<Column style={{ justifyContent: 'center'}} center>
														<Title style={{fontSize: 35}} textAlign={'center'} marginTop={0}>Prices</Title>
														<div style={{height: 3, backgroundColor: theme.colors.crusta, marginBottom: 10}}/>
														{chosenProducts.map((product) => (
															<Paragraph fontSize={'20px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>${`${whatOffer.prices[price][product]}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</Paragraph>
														))}
														<div style={{height: 3, backgroundColor: theme.colors.crusta, marginTop: 20, marginBottom: 20}}/>
														{Object.keys(coupon).length !== 0 && <Title style={{fontSize: 18}}>${`${subTotal}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</Title>}
														{Object.keys(coupon).length !== 0 && <Title style={{fontSize: 18}}>{discount}</Title>}
														<Title style={{fontSize: 25}}>${`${bill}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</Title>
													</Column>
												</Row>
												<Row style={{ marginTop: 15, marginLeft: 0, marginRight: 0 }}>
													<Column>
														<Title fontSize={'25px'} textAlign={'center'} marginBottom={'0em'}>Checkout</Title>
													</Column>
												</Row>
												<Row style={{ marginLeft: 0, marginRight: 0 }}>
													<Checkout
														plan={plans}
														products={chosenProducts}
														discount={discount}
														bill={bill}
														prevPosition={this.prevPosition}
													/>
												</Row>
											</Column>
											<Row style={{ textAlign: 'center', marginLeft: 0, marginRight: 0, paddingLeft: 10, paddingRight: 10 }}>
												<Paragraph style={{ color: '#4a4a4a', fontSize: 12 }}>By pressing "Submit", I certify that I am the person that can make financial decisions and sign agreements on behalf of my organization and I authorize Flourish Change Inc. to charge the card provided and sign up for the flourish platform
				                        			and all charges that correspond to the service provided as defined in the <Link style={{ display: 'inline' }} href="https://storage.googleapis.com/resources.flourishchange.com/Marketing/Contracts/Flourish_SaaS_agreement.pdf"> terms of service</Link>. I also agree to sign up for a subscription of
																Flourish on a monthly or annual recurring billing cycle.
												</Paragraph>
											</Row>
										</CardForm>
									</Column>
								</Row>
							</Container>
						</ScrollableContainer>
					</form>
				</Section>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	error: PropTypes.any,
	pending: PropTypes.any,
	clearErrors: PropTypes.func,
	submitOrgRequest: PropTypes.func,
	success: PropTypes.bool,
	getCategories: PropTypes.func,
	categories: PropTypes.array,
	coupon: PropTypes.object
}

const mapStateToProps = (state) => {
	const { utilState } = state
	return {
		error: utilState.error,
		pending: utilState.pending,
		success: utilState.success,
		categories: utilState.categories,
		coupon: utilState.coupon,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		clearErrors: () => dispatch(clearErrors()),
		submitOrgRequest: (data, callBack) => dispatch(submitOrgRequest(data, callBack)),
		getCategories: (callBack) => dispatch(getCategories(callBack))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IndexPage)
